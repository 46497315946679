import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import {
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { ColorConstants } from '../Constants/ColorConstants';
import { GenderConstants } from '../Constants/GenderConstants';
import { RoutesConstants } from '../Constants/RoutesConstants';
import { clearMask, maskDNI } from '../Helpers/Masks';
import { dniValidate } from '../Helpers/Validations';
import { useDNISearch } from '../Hooks/useDNISearch';
import { useTimer } from '../Hooks/useTimer';
import BackHomeButton from './Buttons/BackHomeButton';
import { DescriptionForm } from './DescriptionsLayout';
import ErrorLabel from './Form/ErrorLabel';
import ErrorMessage from './Form/ErrorMessage';
import InformationLabel from './Form/InformationLabel';
import GrayButton from './LoginBtns/GrayButton';
import PeopleOption from './PeopleOption';
import { useDebounce } from '../Hooks/useDebounce';

export const DNIForm = ({
    action = () => {},
    alreadyExistsAccount = false,
    setAlreadyExistsAccount = false
}) => {
    const { getByDNI, clearPeopleList, peopleList } = useDNISearch({ register: true });
    const [dni, setDni] = useState('');
    const [peopleSelect, setPeopleSelect] = useState();
    const [sexTypeId, setSexTypeId] = useState();
    const [firstSearch, setFirstSearch] = useState(false);
    const [viewHelperText, setViewHelperText] = useState(false);
    const [enableGenderSelect, setEnableGenderSelect] = useState(false);
    const [invalidDNI, setInvalidDNI] = useState(false);
    const [validGetData, setValidGetData] = useState(false);

    const handlePeopleSelect = ({ value, name }) => {
        setPeopleSelect({ name: name, value: value });
    };

    const maxLengthDNI = 10;

    const handleSetDNI = (dni) => {
        if (dni.trim()?.length === 0 || Number(dni) === 0) {
            setInvalidDNI(false);
            setEnableGenderSelect(false);
            clearPeopleList();
            setDni(dni);
            return;
        }
        if (dni?.length <= maxLengthDNI) {
            setInvalidDNI(false);
            setDni(dni);
            if (dni?.length == maxLengthDNI && dniValidate(clearMask(dni))) {
                setEnableGenderSelect(true);
            } else {
                setEnableGenderSelect(false);
                setSexTypeId();
            }
        }
    };

    const handleHelperText = (value) => {
        setViewHelperText(value);
    };

    const onSubmit = () => {
        const person = peopleList.filter((people) => people.personName == peopleSelect.name);
        action({
            ...person[0],
            name: person[0].personName,
            dni: clearMask(dni),
            sexTypeId: sexTypeId
        });
    };

    useEffect(() => {
        const callGetData = async () => {
            if (sexTypeId != undefined && validGetData) {
                await getByDNI({ dni: clearMask(dni), sexTypeId });
                setFirstSearch(true);
                setValidGetData(false);
            }
        };
        callGetData();
    }, [validGetData]);

    useEffect(() => {
        if (dni.trim()?.length > 0 && !dniValidate(clearMask(dni))) {
            setInvalidDNI(true);
            return;
        }
        setInvalidDNI(false);
        clearPeopleList();
        setPeopleSelect();
        setFirstSearch(false);
        if (setAlreadyExistsAccount != false) {
            setAlreadyExistsAccount(false);
        }
        if (dni?.length === maxLengthDNI - 2 && dniValidate(clearMask(dni))) {
            setEnableGenderSelect(true);
        }

        if (sexTypeId != undefined) {
            setValidGetData(true);
        }
    }, [dni, sexTypeId]);

    const description =
        'Tu DNI nos arrojó este resultado ¿Nos confirmás? Si no sos vos, revisá y editá el número ingresado.';
    return (
        <Grid
            display={'flex'}
            flexWrap={'wrap'}
            alignContent={'space-between'}
            height={peopleList?.length > 2 ? 'auto' : 'auto'}>
            <Box>
                <FormControl fullWidth sx={{ borderRadius: '40px', mb: 4 }}>
                    <InputLabel htmlFor="dni">Ingresá tu DNI</InputLabel>
                    <OutlinedInput
                        id="dni"
                        value={maskDNI(dni)}
                        onChange={(e) => handleSetDNI(e.target.value)}
                        onClick={() => handleHelperText(true)}
                        onBlur={() => handleHelperText(false)}
                        sx={{
                            borderRadius: '16px',
                            color: `${ColorConstants.BLUE} !important`,
                            fontWeight: 400
                        }}
                        color="info"
                        error={invalidDNI}
                        startAdornment={
                            <InputAdornment position="start">
                                <BadgeOutlinedIcon />
                            </InputAdornment>
                        }
                        inputProps={{
                            inputMode: 'numeric'
                        }}
                        label="Ingresá tu DNI"
                        // type="number"
                    />
                    {viewHelperText && (
                        <InformationLabel
                            sx={{
                                position: 'absolute',
                                mt: 7,
                                width: '80%'
                            }}
                            description={'Ingresalo sin puntos ni espacios'}
                        />
                    )}
                    {invalidDNI ? (
                        <ErrorLabel
                            sx={{ mt: 3, mb: -3 }}
                            description={'El DNI ingresado no es válido'}
                        />
                    ) : null}
                </FormControl>

                <FormControl fullWidth sx={{ textAlign: 'left' }}>
                    <Box
                        width="100%"
                        sx={{
                            borderRadius: '15px',
                            border: '1px solid',
                            borderColor: '#c4c4c4',
                            textAlign: 'left'
                        }}>
                        <FormLabel id="genre" sx={{ position: 'absolute', marginTop: '-15px' }}>
                            <Typography
                                sx={{
                                    px: '13px',
                                    background: 'white',
                                    padding: '0 5px',
                                    ml: 0.5
                                }}
                                variant="caption">
                                Ingresá tu género
                            </Typography>
                        </FormLabel>
                        <FormGroup
                            id="genre"
                            aria-label="position"
                            row
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                                px: '5%',
                                height: '55px'
                            }}>
                            <div>
                                <FormControlLabel
                                    sx={{
                                        color: ColorConstants.BLUE,
                                        /* '& .MuiTypography-root': {
                                            fontWeight: '700 !important'
                                        }, */
                                        height: '45px'
                                    }}
                                    control={
                                        <Checkbox
                                            sx={{
                                                color: '#C8F8C8',
                                                borderRadius: '5px',
                                                width: '5px',
                                                height: '5px',
                                                marginLeft: '10px',
                                                '&.Mui-checked': {
                                                    color: '#C8F8C8',
                                                    marginLeft: '10px',
                                                    background: '#3e7e3e',
                                                    borderRadius: '5px',
                                                    width: '5px',
                                                    height: '5px'
                                                }
                                            }}
                                        />
                                    }
                                    label={GenderConstants.FEMALE_LABEL}
                                    checked={sexTypeId === GenderConstants.FEMALE}
                                    labelPlacement="start"
                                    disabled={!enableGenderSelect}
                                    onChange={() => setSexTypeId(GenderConstants.FEMALE)}
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    sx={{
                                        color: ColorConstants.BLUE,
                                        /* '& .MuiTypography-root': {
                                            fontWeight: '700 !important'
                                        }, */
                                        height: '45px',
                                        marginRight: '20px'
                                    }}
                                    control={
                                        <Checkbox
                                            sx={{
                                                color: '#C8F8C8',
                                                borderRadius: '5px',
                                                width: '5px',
                                                height: '5px',
                                                marginLeft: '10px',
                                                '&.Mui-checked': {
                                                    color: '#C8F8C8',
                                                    marginLeft: '10px',
                                                    background: '#3e7e3e',
                                                    borderRadius: '5px',
                                                    width: '5px',
                                                    height: '5px'
                                                }
                                            }}
                                        />
                                    }
                                    label={GenderConstants.MALE_LABEL}
                                    checked={sexTypeId === GenderConstants.MALE}
                                    labelPlacement="start"
                                    disabled={!enableGenderSelect}
                                    onChange={() => setSexTypeId(GenderConstants.MALE)}
                                />
                            </div>
                        </FormGroup>
                    </Box>
                </FormControl>

                {!alreadyExistsAccount ? (
                    firstSearch &&
                    peopleList?.length > 0 && (
                        <>
                            <Box p={1}>
                                <DescriptionForm description={description} />
                            </Box>
                            <Container
                                sx={{
                                    borderRadius: '15px',
                                    border: '1px solid',
                                    borderColor: '#c4c4c4',
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                }}>
                                {peopleList.map((people) => (
                                    <PeopleOption
                                        key={people.personName}
                                        people={people}
                                        valueCheck={peopleSelect}
                                        actionCheck={(props) => handlePeopleSelect(props)}
                                    />
                                ))}
                            </Container>
                        </>
                    )
                ) : (
                    <Box width="100%" mt={2}>
                        <ErrorMessage
                            title={'Ya existe una cuenta'}
                            description={
                                <>
                                    El DNI ingresado ya se encuentra registrado{' '}
                                    <Link
                                        href={RoutesConstants.LOGIN_PAGE}
                                        color={ColorConstants.RED}>
                                        Podés iniciar sesión
                                    </Link>
                                </>
                            }
                        />
                    </Box>
                )}
            </Box>

            <Box width="100%" mt={1}>
                {firstSearch && peopleList === undefined ? (
                    <ErrorMessage
                        title={'No encontramos resultados'}
                        description={'Por favor, revisá el DNI ingresado y volvé a intentarlo'}
                    />
                ) : null}

                <Box width="100%">
                    <GrayButton
                        text={'Continuar'}
                        id="crear_cuenta"
                        action={() => onSubmit()}
                        disabled={peopleSelect == undefined}
                    />
                </Box>

                <BackHomeButton />
                {peopleList === undefined || peopleList?.length == 0 ? (
                    <div>
                        <p style={{fontSize: '0.8em', color: 'rgb(122 122 122)', textAlign: 'justify'}}>
                        Las tasas son fijas y se determinan según el historial crediticio del solicitante. Nuestra Tasa Nominal Anual (TNA) es de 220%. Solicitando $2.500.000 en 12 meses, la TNA será de 220%, la TEA será de 654% y el CFT (sin IVA) será de <b><span style={{fontSize: '4.0em'}}>266%</span></b>. La cuota promedio será de $602.993, devolviendo un total de $7.235.920 al final del crédito.
                        </p>
                    </div>
                ) : 
                    <div>
                    </div>
                }
            </Box>
        </Grid>
    );
};
