import React, { useState } from 'react';
import { Link, Grid, Typography } from '@mui/material';
import '../../Pages/PublicHome/PublicHomeStyle.css';
import logo from '../../Assets/logo.svg';
import fiscalData from '../../Assets/fiscal-data.svg';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useLocation } from 'react-router-dom';

export const Footer = () => {
    const location = useLocation();
    const [zoom, setZoom] = useState(null);
    const detectZoom = () => {
        setZoom(window.devicePixelRatio);
    };
    window.visualViewport.addEventListener('resize', detectZoom);

    return (
        <Grid container spacing={2} className="footer">
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
                <img src={logo} />
            </Grid>
            <Grid item xs={12} sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
            <Grid item xs={12} sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
            <Grid container item xs={12} sm={9}>
                <Grid item xs={12} sm={12} md={1} mr={1} mb={2}>
                    <Link
                        onClick={() => history.push(location.pathname)}
                        href={RoutesConstants.CONTACT_PAGE}
                        underline="hover"
                        sx={{
                            fontSize:
                                (zoom > 1.1 && zoom < 1.25 && '14px') ||
                                (zoom == 1.25 && '12px') ||
                                (zoom == 1.5 && '10px')
                        }}>
                        {' '}
                        Contacto
                    </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={2} mr={2} mb={2}>
                    <Link
                        onClick={() => history.push(location.pathname)}
                        href={RoutesConstants.FRECUENT_QUESTIONS_PAGE}
                        underline="hover"
                        sx={{
                            fontSize:
                                (zoom > 1.1 && zoom < 1.25 && '14px') ||
                                (zoom == 1.25 && '12px') ||
                                (zoom == 1.5 && '10px')
                        }}>
                        {' '}
                        Preguntas frecuentes
                    </Link>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    mr={(zoom == 1.5 && -4) || (zoom < 1.25 && -7) || (zoom == 1.25 && -5.5)}
                    mb={2}>
                    <Link
                        onClick={() => history.push(location.pathname)}
                        href={RoutesConstants.PRIVACY_PAGE}
                        underline="hover"
                        sx={{
                            fontSize:
                                (zoom > 1.1 && zoom < 1.25 && '14px') ||
                                (zoom == 1.25 && '12px') ||
                                (zoom == 1.5 && '10px')
                        }}>
                        {' '}
                        Políticas de privacidad
                    </Link>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    mr={(zoom == 1.5 && -4) || (zoom < 1.25 && -7) || (zoom == 1.25 && -5.5)}
                    mb={2}>
                    <Link
                        onClick={() => history.push(location.pathname)}
                        href={RoutesConstants.TERMS_PAGE}
                        underline="hover"
                        sx={{
                            fontSize:
                                (zoom > 1.1 && zoom < 1.25 && '14px') ||
                                (zoom == 1.25 && '12px') ||
                                (zoom == 1.5 && '10px')
                        }}>
                        {' '}
                        Términos y condiciones
                    </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Link
                        onClick={() => history.push(location.pathname)}
                        href={RoutesConstants.FINANCIAL_USER_INFO}
                        underline="hover"
                        sx={{
                            fontSize:
                                (zoom > 1.1 && zoom < 1.25 && '14px') ||
                                (zoom == 1.25 && '12px') ||
                                (zoom == 1.5 && '10px')
                        }}>
                        {' '}
                        Información al usuario financiero
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={12}>
                <div className="divider"></div>
            </Grid>
            <Grid item sm={2}></Grid>
            <Grid container item spacing={1} xs={12} sm={8}>
                <Grid item xs={11} sm={11}>
                    <Typography className="font-footer">
                        Crédito Directo S.A. - CUIT N° 30-71210113-6
                    </Typography>
                    <Typography className="font-footer">Perón 646, Piso 4°</Typography>
                    <Typography className="font-footer">Ciudad Autónoma de Buenos Aires</Typography>
                </Grid>
                <Grid item xs={1} sm={1} sx={{ cursor: 'pointer' }}>
                    <a
                        href="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=KzwuOJhGPsmIhmBT8kDbVQ,,"
                        target="_blank"
                        rel="noreferrer">
                        <img src={fiscalData} style={{ width: '60%' }} />
                    </a>
                </Grid>
            </Grid>
            <Grid item sm={2}></Grid>
        </Grid>
    );
};
