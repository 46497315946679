import StyledFooter, { AddressContainer } from './Footer.styled';
import { Box, Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import fiscalData from '../../../Assets/fiscal-data.svg';
import isologo from '../../../Assets/logo.svg';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
const { REACT_APP_ENABLE_BNPL_FAQ } = process.env;

export const Footer = () => {
    return (
        <StyledFooter px={[3, 3, '5%', '11.5%']} py={5}>
            <Stack className="links-container" spacing={2}>
                <Box width="100%" pl="5px">
                    <img src={isologo} alt="Directo" />
                </Box>
                <Grid container item spacing={2}>
                    <Grid item xs={12} md="auto">
                        <Link to={RoutesConstants.CONTACT_PAGE}>Contacto</Link>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Link
                            to={
                                REACT_APP_ENABLE_BNPL_FAQ === 'true'
                                    ? RoutesConstants.FRECUENT_QUESTIONS_PAGE_BNPL
                                    : RoutesConstants.FRECUENT_QUESTIONS_PAGE
                            }>
                            Preguntas frecuentes
                        </Link>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Link to={RoutesConstants.PRIVACY_PAGE}>Políticas de privacidad</Link>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Link to={RoutesConstants.TERMS_PAGE}>Términos y condiciones</Link>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Link to={RoutesConstants.FINANCIAL_USER_INFO}>
                            Información al usuario financiero
                        </Link>
                    </Grid>
                </Grid>
            </Stack>
            <AddressContainer spacing={2} direction="row">
                <Stack>
                    <p>Crédito Directo S.A. – CUIT N° 30-71210113-6</p>
                    <p>Perón 646, Piso 4°</p>
                    <p>Ciudad Autónoma de Buenos Aires</p>
                </Stack>
                <Link
                    to="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=KzwuOJhGPsmIhmBT8kDbVQ,,"
                    target="_blank"
                    rel="noreferrer">
                    <img src={fiscalData} />
                </Link>
            </AddressContainer>
        </StyledFooter>
    );
};
